import { useEffect, useState, useRef } from "react";
import { taxConfig } from ".././configData.js";

const InvestmentAllowance = ({
  reportPhase,
  title,
  active,
  setActive,
  provFund,
  totalTaxIncome,
  totalPayableTax,
  zone,
}) => {
  const [totalInvestMent, setTotalInvestMent] = useState(0);
  const [allowedInvestmentAmount, setAllowedInvestmentAmount] = useState(0);
  const [limitInvestment, setLimitInvestment] = useState(0);
  const [lessRebate, setLessRebate] = useState(0);
  const [netIncomeTaxPayable, setNetIncomeTaxPayable] = useState(0);
  const dpsField = useRef(0);
  const [lessRebateParents, setLessRebateParents] = useState(0);
  const dpsInput = useRef(0);

  useEffect(() => {
    let minimumTax = taxConfig.zone.cityCorporation;

    if (zone === "cityCorporation") {
      minimumTax = taxConfig.zone.cityCorporation;
    } else if (zone === "otherCity") {
      minimumTax = taxConfig.zone.otherCity;
    } else {
      minimumTax = taxConfig.zone.restCountry;
    }

    const investableAmount =
      totalTaxIncome * taxConfig.config.maxInvestTaxExemption * 0.01; // maxInvestTaxExemption = 20

    // Set investment
    if (investableAmount <= taxConfig.config.maxAllowedInvesment) {
      // maxAllowedInvesment = 15000000
      setAllowedInvestmentAmount(investableAmount);
    } else {
      setAllowedInvestmentAmount(taxConfig.config.maxAllowedInvesment);
    }

    // SET THE AMOUNT THAT A USER INVESTED
    setTotalInvestMent(provFund + Number(dpsField.current.value));

    const lessRebateParentsvar = Math.min(
      allowedInvestmentAmount,
      totalInvestMent
    );
    setLessRebateParents(lessRebateParentsvar);
    setLimitInvestment(
      Math.max(investableAmount, taxConfig.config.maxAllowedInvesment)
    );

    // 15% of 15000000 or 3% of lessRebateParents, whichever is less
    let rebate = 0;

    // // if dpsField.current.value exists, calculate rebate on totalTaxIncome or dpsField.current.value whichever is less
    // if (totalTaxIncome > dpsField.current.value) {
    //   rebate = Math.min(
    //     (15 / 100) * taxConfig.config.maxAllowedInvesment,
    //     (3 / 100) * totalTaxIncome
    //   );
    // } else {
    //   rebate = Math.min(
    //     (15 / 100) * taxConfig.config.maxAllowedInvesment,
    //     (3 / 100) * Number(dpsField.current.value)
    //   );
    // }

    if (Number(dpsField.current.value)) {
      rebate = Math.min(
        (15 / 100) * Number(dpsField.current.value),
        (3 / 100) * totalTaxIncome
      );
    } else {
      rebate = Math.min(
        (15 / 100) * taxConfig.config.maxAllowedInvesment,
        (3 / 100) * totalTaxIncome
      );
    }

    // setLessRebate(lessRebateParents * 0.15);
    setLessRebate(rebate);

    let netIncomeTaxAmount = Math.max(minimumTax, totalPayableTax - lessRebate);

    if (totalPayableTax === 0) {
      netIncomeTaxAmount = 0;
    }
    setNetIncomeTaxPayable(netIncomeTaxAmount);
  }, [
    totalInvestMent,
    netIncomeTaxPayable,
    allowedInvestmentAmount,
    lessRebate,
    totalPayableTax,
    limitInvestment,
    lessRebateParents,
    totalTaxIncome,
    zone,
    provFund,
  ]);

  var format = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "BDT",
    minimumFractionDigits: 0,
  });

  return (
    <>
      {reportPhase ? (
        <div className="table-responsive">
          <p className="All_Headings">Taxable Income</p>
          <table
            className="table table-hover table-bordered total_calc"
            id="investmentTable"
          >
            <thead>
              <tr className="total_of_IncomeDetails">
                <th scope="col">Investment Allowance and Tax Rebate</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contribution to Provident Fund</td>
                <td className="text-center">{provFund}</td>
              </tr>
              <tr>
                <td>DPS/BSP/LIP/Others (If Applicable)</td>
                <td className="text-center table_form">
                  {format.format(dpsInput.current)}
                </td>
              </tr>
              <tr>
                <td>Total Investment</td>
                <td className="text-center">
                  {format.format(totalInvestMent)}
                </td>
              </tr>
              <tr>
                <td>
                  Maximum Allowed Investment (
                  {taxConfig.config.maxInvestTaxExemption}% of Total{" "}
                  {format.format(totalTaxIncome)} ){" "}
                </td>
                <td className="text-center">
                  {format.format(allowedInvestmentAmount)} From{" "}
                  {format.format(limitInvestment)}
                </td>
              </tr>

              <tr>
                <td>
                  Rebate on Investment Tk (
                  {Number(dpsField.current.value)
                    ? `15% of ${Number(dpsField.current.value)} or ${
                        taxConfig.config.rebatePercentageOnInvestment
                      }% of ${format.format(totalTaxIncome)}, whichever less`
                    : `15% of ${taxConfig.config.maxAllowedInvesment} or ${
                        taxConfig.config.rebatePercentageOnInvestment
                      }% of ${format.format(
                        totalTaxIncome
                      )}, whichever less`}{" "}
                  )
                </td>
                <td className="text-center">
                  {
                    format.format(lessRebate + " ")
                    // +
                    // "   (15% of " +
                    // format.format(lessRebateParents) +
                    // ")"
                  }
                </td>
              </tr>
              <tr>
                <td colSpan="1">Net Payable Income Tax</td>
                <td className="text-center ">
                  <p className="fw-bold">
                    {format.format(netIncomeTaxPayable)}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="accordion-item">
          <h2
            className="accordion-header"
            onClick={() => {
              if (title === active) {
                setActive("");
              } else setActive(title);
            }}
            id="headingThree"
          >
            <button
              className={
                "accordion-button " + (title === active ? "togolBtn" : "")
              }
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              Total Calculation{" "}
              <span>
                {title !== active
                  ? "Net Income Tax  : " + format.format(netIncomeTaxPayable)
                  : ""}
              </span>
            </button>
          </h2>
          <div
            id="collapseThree"
            className={
              "accordion-collapse collapse " + (active === title ? "show" : "")
            }
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <table className="table table-hover table-bordered total_calc">
                <thead>
                  <tr className="total_of_IncomeDetails">
                    <th scope="col">Investment Allowance and Tax Rebate</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Contribution to Provident Fund</td>
                    <td className="text-center">{provFund}</td>
                  </tr>
                  <tr>
                    <td>
                      DPS/BSP/LIP/Others (If Applicable) <br />
                      <i>You have any investment please make input</i>
                    </td>
                    <td className="text-center table_form">
                      <input
                        type="number"
                        ref={dpsField}
                        placeholder="Waiting for input"
                        defaultValue={0}
                        onChange={(e) => {
                          let DPSOthers = e.target.value;
                          dpsInput.current = DPSOthers;
                          setTotalInvestMent(
                            DPSOthers > 0
                              ? parseFloat(provFund) + parseFloat(DPSOthers)
                              : parseFloat(provFund)
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Total Investment</td>
                    <td className="text-center">
                      {format.format(totalInvestMent)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Maximum Allowed Investment (
                      {taxConfig.config.maxInvestTaxExemption}% of Total{" "}
                      {format.format(totalTaxIncome)} ){" "}
                    </td>
                    <td className="text-center">
                      {format.format(allowedInvestmentAmount)} From{" "}
                      {format.format(limitInvestment)}{" "}
                      <span className="tul-tip">{"(i)"}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Rebate on Investment Tk (
                      {Number(dpsField.current.value)
                        ? `15% of ${Number(dpsField.current.value)} or ${
                            taxConfig.config.rebatePercentageOnInvestment
                          }% of ${format.format(
                            totalTaxIncome
                          )}, whichever less`
                        : `15% of ${taxConfig.config.maxAllowedInvesment} or ${
                            taxConfig.config.rebatePercentageOnInvestment
                          }% of ${format.format(
                            totalTaxIncome
                          )}, whichever less`}{" "}
                      )
                    </td>
                    <td className="text-center">
                      {
                        format.format(lessRebate + " ")
                        // +
                        //   "   (15% of " +
                        //   format.format(lessRebateParents) +
                        //   ") "
                      }
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1">Net Payable Income Tax </td>
                    <td className="text-center ">
                      <p className="fw-bold">
                        {format.format(netIncomeTaxPayable)}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvestmentAllowance;
