import React, { useState, useRef, useEffect, createRef } from "react";
import "./Calculator.css";
import jsPDF from "jspdf";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import html2pdf from 'html2pdf';
import "jspdf-autotable";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../components/Header";
import { InComeDetails } from "../components/IncomeDetails.js";
import UserDetails from "../components/UserDetails";
import { UpdateIncomeDetails } from "../components/UpdateIncomeDetails";
import ReportHeader from "../components/ReportHeader";
import TaxableIncome from "../components/TaxableIncome";
import TotalTax from "../components/TotalTax.js";
import InvestmentAllowance from "../components/InvestMentAllowence.js";
import { taxConfig } from "../configData.js";
import { calculatePayableTax } from "../utils.js";
import { TaxTable } from "../components/TaxTable";
import Footer from "../components/Footer";

function Calculator() {
  const [showTaxModal, setShowTaxModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [category, setCategory] = useState("general");
  const [formData, setUserData] = useState({});
  const [totalTaxableIncome, setTotalTaxableIncome] = useState(0);
  const [totalpayable, setTotalpayable] = useState(0);
  const housingLessRef = useRef(0);
  const medicalLesssRef = useRef(0);
  const conveyanceLesssRef = useRef(0);
  const taxableBasicRef = useRef(0);
  const taxableMedicalRef = useRef(0);
  const taxableHousingRef = useRef(0);
  const taxableConveyanceRef = useRef(0);
  const taxableOthersRef = useRef(0);
  const [reportPhase, setReportPhase] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [zone, setZone] = useState("");
  const [createBtnShow, setCreateBtnShow] = useState(true);
  const [active, setActive] = useState("taxableIncome");
  const captureRef = createRef(null);

  const handleUserInfo = (userInfo, submit) => {
    setUserInfo(userInfo);
    setReportPhase(submit);
  };

  let currentYear, nextYear;
  if (new Date().getMonth() >= 6 && new Date().getMonth() <= 11) {
    currentYear = new Date().getFullYear();
    nextYear = currentYear + 1;
  } else {
    nextYear = new Date().getFullYear();
    currentYear = nextYear - 1;
  }

  useEffect(() => {
    let newTotalPayable = calculatePayableTax(totalTaxableIncome, category);
    setTotalpayable(newTotalPayable);
  }, [category, totalTaxableIncome, formData, totalpayable]);

  function generate() {
    var doc = new jsPDF("p", "pt", "letter");
    var htmlstring = "";
    var tempVarToCheckPageHeight = 0;
    var pageHeight = doc.internal.pageSize.height;
    var specialElementHandlers = {
      // element with id of "bypass" - jQuery style selector
      "#bypassme": function (element, renderer) {
        // true = "handled elsewhere, bypass text extraction"
        return true;
      },
    };
    var margins = {
      top: 10,
      bottom: 10,
      left: 70,
      right: 70,
      width: 780,
    };
    var y = 20;
    doc.setLineWidth(2);
    doc.text(
      170,
      (y = y + 30),
      `"Tax Statement For The Year ${currentYear} - ${nextYear}"`
    );
    doc.autoTable({
      html: "#userTable",
      startY: 70,
      theme: "grid",
      // columnStyles: {
      //     0: {
      //         cellWidth: 100,
      //     },
      //     1: {
      //         cellWidth: 100,
      //     }
      // },
      styles: {
        minCellHeight: 16,
      },
      margin: margins,
      didParseCell: function (table) {
        if (table.section === "head") {
          table.cell.styles.fillColor = "#dee2e6";
          table.cell.styles.textColor = "#525252";
        }
      },
    });
    doc.autoTable({
      html: "#taxableTable",
      startY: doc.lastAutoTable.finalY + 10,
      theme: "grid",
      // columnStyles: {
      //     0: {
      //         cellWidth: 70,
      //     },
      //     1: {
      //         cellWidth: 120,
      //     },
      //     2: {
      //         cellWidth: 70,
      //     } ,
      //     3: {
      //       cellWidth: 80,
      //   },
      //   4: {
      //     cellWidth: 100,
      // }
      // },
      styles: {
        minCellHeight: 16,
      },
      margin: margins,
      didParseCell: function (table) {
        if (table.section === "head") {
          table.cell.styles.fillColor = "#dee2e6";
          table.cell.styles.textColor = "#525252";
        }
      },
    });
    doc.setFontSize(11);
    doc.text(240, doc.lastAutoTable.finalY + 30, "Taxable Income");
    doc.autoTable({
      html: "#totalTaxTable",
      startY: doc.lastAutoTable.finalY + 50,
      theme: "grid",
      // columnStyles: {
      //     0: {
      //         cellWidth: 60,
      //     },
      //     1: {
      //         cellWidth: 90,
      //     },
      //     2: {
      //         cellWidth: 40,
      //     },
      //     3: {
      //       cellWidth: 100,
      //   },
      //     4: {
      //       cellWidth: 50,
      //   },
      //   5: {
      //     cellWidth: 120,
      // },
      // },
      styles: {
        minCellHeight: 16,
      },
      margin: margins,
      didParseCell: function (table) {
        if (table.section === "head") {
          table.cell.styles.fillColor = "#dee2e6";
          table.cell.styles.textColor = "#525252";
        }
      },
    });

    doc.autoTable({
      html: "#investmentTable",
      startY: doc.lastAutoTable.finalY + 10,
      theme: "grid",
      // columnStyles: {
      //     0: {
      //         cellWidth: 140,
      //     },
      //     1: {
      //         cellWidth: 160,
      //     }
      // },
      styles: {
        minCellHeight: 16,
      },
      margin: margins,
      didParseCell: function (table) {
        if (table.section === "head") {
          table.cell.styles.fillColor = "#dee2e6";
          table.cell.styles.textColor = "#525252";
        }
      },
    });
    doc.setFontSize(9);

    doc.text(
      240,
      doc.lastAutoTable.finalY + 15,
      `©${new Date().getFullYear()} VivaSoft, All right reserved.`
    );

    //   doc.text(
    //     240,
    //     doc.lastAutoTable.finalY + 30,
    //     "©2021 VivaSoft, All right reserved."
    //   );
    doc.save("Tax_Statement.pdf");
  }

  const handleFormSubmit = (formData, submitted) => {
    setZone(formData.zone);
    setUserData(formData);
    setCategory(formData.category);
    const newHousingLess = Math.min(
      formData.basicAmount *
        formData.pvMonths *
        (taxConfig.lessAmount.maxHousingPercentage / 100.0),
      taxConfig.lessAmount.maxHousing
    );
    const newMedicalLess = Math.min(
      formData.basicAmount *
        formData.pvMonths *
        (taxConfig.lessAmount.maxMedicalPercentage / 100.0),
      taxConfig.lessAmount.maxMedical
    );

    conveyanceLesssRef.current = taxConfig.lessAmount.maxConveyance;
    //setHousingLess(newHousingLess);
    housingLessRef.current = newHousingLess;
    medicalLesssRef.current = newMedicalLess;
    taxableBasicRef.current = formData.basicAmount * formData.pvMonths;
    taxableMedicalRef.current = Math.max(
      formData.medicalAmount * formData.pvMonths - medicalLesssRef.current,
      0
    );
    taxableHousingRef.current = Math.max(
      formData.housingAmount * formData.pvMonths - housingLessRef.current,
      0
    );
    taxableConveyanceRef.current = Math.max(
      formData.conveyanceAmount * formData.pvMonths -
        taxConfig.lessAmount.maxConveyance,
      0
    );
    taxableOthersRef.current = formData.othersAmount * formData.pvMonths;

    const totaltaxIncome =
      taxableBasicRef.current +
      taxableMedicalRef.current +
      taxableHousingRef.current +
      taxableConveyanceRef.current +
      formData.bonusAmount +
      formData.provFund +
      taxableOthersRef.current;

    setTotalTaxableIncome(totaltaxIncome);
    //totalTaxableIncome.current=totaltaxIncome
    // setCategory(category);
    //setTotalpayable(calculatePayableTax(totalTaxableIncome, category));
    setActive("taxableIncome");
    setFormSubmitted(submitted);
  };

  const backBtnClick = () => {
    setUserData({});
    setCreateBtnShow(true);
    setReportPhase(false);
    setFormSubmitted(false);
  };

  return (
    <>
      <Router>
        <>
          {/* <Header /> */}
          {/*<Navbar updateCategory={(newCategory) => setCategory(newCategory)} />*/}

          {!formSubmitted && !!formData ? (
            <Switch>
              <Route path="/">
                <InComeDetails
                  changeCategory={(newCategory) => setCategory(newCategory)}
                  handleStates={(formData, submitted) =>
                    handleFormSubmit(formData, submitted)
                  }
                />
              </Route>
            </Switch>
          ) : (
            <>
              <div className="container">
                <div className="row ">
                  <div
                    className={
                      !reportPhase
                        ? "accordion col-md-8 col-sm-12"
                        : "row report_page"
                    }
                    id="accordionExample"
                    ref={captureRef}
                  >
                    {reportPhase ? (
                      <>
                        <div className="button_container">
                          <button className=" backBtn" onClick={backBtnClick}>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              width="20px"
                              data-prefix="fas"
                              data-icon="long-arrow-alt-left"
                              class="svg-inline--fa fa-long-arrow-alt-left fa-w-14"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                              ></path>
                            </svg>{" "}
                            <span>Back</span>
                          </button>
                          <button
                            className="download_report"
                            onClick={generate}
                          >
                            <span>Download Statement</span>
                            <svg
                              width="20px"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="download"
                              class="svg-inline--fa fa-download fa-w-16"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : null}

                    {reportPhase === true ? (
                      <ReportHeader userInfo={userInfo} region={zone} />
                    ) : null}

                    <TaxableIncome
                      reportPhase={reportPhase}
                      title="taxableIncome"
                      active={active}
                      setActive={setActive}
                      formBasic={formData.basicAmount}
                      formHousing={formData.housingAmount}
                      formMedical={formData.medicalAmount}
                      formConveyance={formData.conveyanceAmount}
                      formOthers={formData.othersAmount}
                      formPvMonths={formData.pvMonths}
                      formBonus={formData.bonusAmount}
                      formProvFund={formData.provFund}
                      totalTaxableIncome={totalTaxableIncome}
                      housingLess={housingLessRef.current}
                      medicalLesss={medicalLesssRef.current}
                      conveyanceLesss={conveyanceLesssRef.current}
                      taxableBasic={taxableBasicRef.current}
                      taxableHousing={taxableHousingRef.current}
                      taxableMedical={taxableMedicalRef.current}
                      taxableConveyance={taxableConveyanceRef.current}
                      taxableOthers={taxableOthersRef.current}
                    />

                    <TotalTax
                      reportPhase={reportPhase}
                      title="totalTax"
                      setTotalpayable={setTotalpayable}
                      active={active}
                      setActive={setActive}
                      category={category}
                      totalTaxableIncome={totalTaxableIncome}
                    />
                    <InvestmentAllowance
                      reportPhase={reportPhase}
                      title="investmentAllowance"
                      active={active}
                      setActive={setActive}
                      provFund={formData.provFund}
                      zone={zone}
                      totalTaxIncome={totalTaxableIncome}
                      totalPayableTax={totalpayable}
                    />
                    <div style={{ margin: "0px" }}>
                      {createBtnShow && (
                        <button
                          type="button"
                          className="create_report"
                          onClick={() => setCreateBtnShow(false)}
                        >
                          View Details
                        </button>
                      )}
                      {reportPhase === false && createBtnShow === false ? (
                        <UserDetails
                          handleUserSubmit={(userData, submitted) =>
                            handleUserInfo(userData, submitted)
                          }
                        />
                      ) : null}
                    </div>
                  </div>

                  {!reportPhase ? (
                    <div className="col-md-4 col-sm-12">
                      <UpdateIncomeDetails
                        yearlyCheck={formData.yearlyCheck}
                        updateZone={(newZone) => setZone(newZone)}
                        updateCategory={(newCategory) =>
                          setCategory(newCategory)
                        }
                        formData={formData}
                        handleStates={(formData, submitted) =>
                          handleFormSubmit(formData, submitted)
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}

          <div
            className="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <TaxTable category={category} />
            </div>
          </div>
        </>
      </Router>
    </>
  );
}

export default Calculator;
