import React from "react";
import { useRef, useEffect } from "react";

let currentYear, nextYear;
if (new Date().getMonth() >= 6 && new Date().getMonth() <= 11) {
  currentYear = new Date().getFullYear();
  nextYear = currentYear + 1;
} else {
  nextYear = new Date().getFullYear();
  currentYear = nextYear - 1;
}

function ReportHeader({ userInfo, region }) {
  const regionVar = useRef(null);
  useEffect(() => {
    if (region === "cityCorporation") {
      regionVar.current = "Dhaka/Gazipur/Chitagong";
    } else if (region === "otherCity") {
      regionVar.current = "City Corporation Area";
    } else regionVar.current = "Country Side";
  }, []);
  let date = new Date();
  let getYear = date.getFullYear();
  let getMonth = date.getMonth();
  let TaxYear = "";
  if (getMonth <= 5) {
    TaxYear = `${getYear - 1} - ${getYear}`;
  } else {
    TaxYear = `${getYear} - ${getYear + 1}`;
  }
  return (
    <div className="container">
      <h2 className="text-center report_header">
        Tax Statement
        <span>
          {" "}
          Tax year : {currentYear}-{nextYear}{" "}
        </span>
      </h2>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table
              className="table table-hover table-bordered border-dark "
              id="userTable"
            >
              <thead>
                <tr className="total_of_IncomeDetails">
                  <th scope="col">User Information</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name : {userInfo.name}</td>
                  <td>Email : {userInfo.email}</td>
                </tr>
                <tr>
                  <td>Company: {userInfo.company}</td>
                  <td>Mobile No : {userInfo.phone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportHeader;
