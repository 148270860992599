import React from "react";
let date = new Date();
let getYear = date.getFullYear();
function Footer({ formSubmitted }) {
  return (
    <>
      <div
        className={
          formSubmitted === true
            ? "container-fluid footer_div "
            : " home_footer container-fluid footer_div "
        }
      >
        <p className="footerDetails">
          ©{getYear} <a href="https://www.vivasoftltd.com/">VivaSoft</a>, All
          right reserved.
        </p>
      </div>
    </>
  );
}

export default Footer;
