import React, { useEffect, useState, useLayoutEffect } from "react";
import { taxConfig } from "../configData.js";

function Totaltax(props) {
  const [conditon1, setCondition1] = useState(0);
  const [conditon2, setCondition2] = useState(0);
  const [conditon3, setCondition3] = useState(0);
  const [conditon4, setCondition4] = useState(0);
  const [conditon5, setCondition5] = useState(0);
  const [conditon6, setCondition6] = useState(0);

  //amount
  const [amount1, setAmount1] = useState(0); // total taxable income
  const [amount2, setAmount2] = useState(0);
  const [amount3, setAmount3] = useState(0);
  const [amount4, setAmount4] = useState(0);
  const [amount5, setAmount5] = useState(0);
  const [amount6, setAmount6] = useState(0);

  //dividable amount
  const [taxable1, setTaxable1] = useState(0);
  const [taxable2, setTaxable2] = useState(0);
  const [taxable3, setTaxable3] = useState(0);
  const [taxable4, setTaxable4] = useState(0);
  const [taxable5, setTaxable5] = useState(0);
  const [taxable6, setTaxable6] = useState(0);

  useLayoutEffect(() => {
    setCondition1(0);
    setCondition2(0);
    setCondition3(0);
    setCondition4(0);
    setCondition5(0);
    setCondition6(0);
    setAmount1(0);
    setAmount2(0);
    setAmount3(0);
    setAmount4(0);
    setAmount5(0);
    setAmount6(0);
    setTaxable1(0);
    setTaxable2(0);
    setTaxable3(0);
    setTaxable4(0);
    setTaxable5(0);
    setTaxable6(0);
  }, [props]);

  console.log({ income: props.totalTaxableIncome });

  const handleChange = () => {
    // AMOUNT OF TOTAL TAXABLE INCOME
    let item = props.totalTaxableIncome;
    console.log({ item });
    setAmount1(item);

    // old
    // let FIRST_STAGE_TAX = 5000;
    // let SECOND_STAGE_TAX = 30000;
    // let THIRD_STAGE_TAX = 60000;
    // let FOURTH_STAGE_TAX = 100000;

    // new
    let FIRST_STAGE_TAX = 5000; // 5% of 1,00,000 | amount of tax for 1,00,000 after 3,50,000
    let SECOND_STAGE_TAX = 40000; // 10% of 4,00,000  | amount of tax for 4,00,000 after previous stage
    let THIRD_STAGE_TAX = 75000; // 15% of 5,00,000  | amount of tax for 5,00,000 after previous stage
    let FOURTH_STAGE_TAX = 100000; // 20% of 5,00,000  | amount of tax for 5,00,000 after previous stage

    let t1, t2, t3, t4, t5;

    if (props.category === "disabled")
      [t1, t2, t3, t4, t5] = [...taxConfig.taxRules.disabled];
    else if (props.category === "general")
      [t1, t2, t3, t4, t5] = [...taxConfig.taxRules.general];
    else if (props.category === "freedomFighters")
      [t1, t2, t3, t4, t5] = [...taxConfig.taxRules.freedomFighters];
    else [t1, t2, t3, t4, t5] = [...taxConfig.taxRules.oldAge];

    let sumOfTaxArray1 = t1 + t2 + t3 + t4 + t5; // 25% TAX
    let sumOfTaxArray2 = t1 + t2 + t3 + t4; // 20% TAX
    let sumOfTaxArray3 = t1 + t2 + t3; // 15% TAX
    let sumOfTaxArray4 = t1 + t2; // 10% TAX
    let sumOfTaxArray5 = t1; // 5% TAX

    // condition1 || 25% tax applicable
    if (item > sumOfTaxArray1) {
      console.log("condition1");
      let amnt2 = item - t1;
      setAmount2(amnt2);
      let amnt3 = amnt2 - t2;
      setAmount3(amnt3);
      let amnt4 = amnt3 - t3;
      setAmount4(amnt4);
      let amnt5 = amnt4 - t4;
      setAmount5(amnt5);
      let amnt6 = amnt5 - t5;
      setAmount6(amnt6);

      let tax1 = item - sumOfTaxArray1;
      setTaxable1(t1);
      setTaxable2(t2);
      setTaxable3(t3);
      setTaxable4(t4);
      setTaxable5(t5);
      setTaxable6(tax1);

      let paidTax1 = (tax1 * 25) / 100;
      let totalTax1 =
        paidTax1 +
        FIRST_STAGE_TAX +
        SECOND_STAGE_TAX +
        THIRD_STAGE_TAX +
        FOURTH_STAGE_TAX;
      setCondition1(FIRST_STAGE_TAX);
      setCondition2(SECOND_STAGE_TAX);
      setCondition3(THIRD_STAGE_TAX);
      setCondition4(FOURTH_STAGE_TAX);
      setCondition5(paidTax1);
      setCondition6(totalTax1);
      props.setTotalpayable(totalTax1);
    }

    //condition2 || 20% tax applicable
    else if (item > sumOfTaxArray2) {
      console.log("condition2");
      let amnt2 = item - t1;
      setAmount2(amnt2);
      let amnt3 = amnt2 - t2;
      setAmount3(amnt3);
      let amnt4 = amnt3 - t3;
      setAmount4(amnt4);
      let amnt5 = amnt4 - t4;
      setAmount5(amnt5);

      let tax2 = item - sumOfTaxArray2;

      setTaxable1(t1);
      setTaxable2(t2);
      setTaxable3(t3);
      setTaxable4(t4);
      setTaxable5(tax2);

      let paidTax2 = (tax2 * 20) / 100;
      let totalTax2 =
        paidTax2 + FIRST_STAGE_TAX + SECOND_STAGE_TAX + THIRD_STAGE_TAX;

      setCondition1(FIRST_STAGE_TAX);
      setCondition2(SECOND_STAGE_TAX);
      setCondition3(THIRD_STAGE_TAX);
      setCondition4(paidTax2);
      setCondition6(totalTax2);
      props.setTotalpayable(totalTax2);
    }

    //condition3 || 15% tax applicable
    else if (item > sumOfTaxArray3) {
      console.log("condition3");
      let amnt2 = item - t1;
      setAmount2(amnt2);
      let amnt3 = amnt2 - t2;
      setAmount3(amnt3);
      let amnt4 = amnt3 - t3;
      setAmount4(amnt4);

      let tax3 = item - sumOfTaxArray3;
      setTaxable1(t1);
      setTaxable2(t2);
      setTaxable3(t3);
      setTaxable4(tax3);

      let paidTax3 = (tax3 * 15) / 100;
      let totalTax3 = paidTax3 + FIRST_STAGE_TAX + SECOND_STAGE_TAX;
      setCondition1(FIRST_STAGE_TAX);
      setCondition2(SECOND_STAGE_TAX);
      setCondition3(paidTax3);
      setCondition6(totalTax3);
      props.setTotalpayable(totalTax3);
    }

    //condition4 || 10% tax applicable
    else if (item > sumOfTaxArray4) {
      console.log("condition4");
      console.log({ item, t1 });
      let amnt2 = item - t1;
      setAmount2(amnt2);
      let amnt3 = amnt2 - t2;
      setAmount3(amnt3);

      let tax4 = item - sumOfTaxArray4;
      console.log({ tax4 });
      setTaxable1(t1);
      setTaxable2(t2);
      setTaxable3(tax4);

      let paidTax4 = (tax4 * 10) / 100;
      let totalTax4 = paidTax4 + FIRST_STAGE_TAX;
      setCondition1(FIRST_STAGE_TAX);
      setCondition2(paidTax4);
      setCondition6(totalTax4);
      props.setTotalpayable(totalTax4);
    }

    //condition5 || 5% tax applicable
    else if (item > sumOfTaxArray5) {
      console.log("condition5");
      let amnt2 = item - t1;
      setAmount2(amnt2);

      let tax5 = item - sumOfTaxArray5;

      setTaxable1(t1);
      setTaxable2(tax5);

      let totalTax5 = (tax5 * 5) / 100;
      setCondition1(totalTax5);
      setCondition6(totalTax5);
      props.setTotalpayable(totalTax5);
    }
  };

  useEffect(() => handleChange(), [props]);

  var format = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "BDT",
    minimumFractionDigits: 0,
  });

  console.log("taxable1", taxable1);

  return (
    <>
      {props.reportPhase ? (
        <div className="table-responsive">
          <table
            className="table table-hover table-bordered border-dark "
            id="totalTaxTable"
          >
            {/* All headings */}
            <thead>
              <tr className="total_of_IncomeDetails">
                <th scope="col" className="text-center">
                  Payable Tax
                </th>
                <th scope="col" className="text-center"></th>
                <th scope="col" className="text-center"></th>
                <th scope="col" className="text-center"></th>
                <th scope="col" className="text-center">
                  Rate
                </th>
                <th scope="col" className="text-center">
                  Tax
                </th>
              </tr>
            </thead>
            <tbody>
              {/* 1st item */}
              {!!taxable1 && (
                <tr>
                  <td className="withoutInputFields td_of_charts">First Tk.</td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(taxable1)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">From</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(amount1)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">0%</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">BDT 0</p>
                  </td>
                </tr>
              )}
              {/* 2nd item */}
              {!!taxable2 && (
                <tr>
                  <td className="td_of_charts">Next Tk.</td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(taxable2)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">From</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(amount2)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">5%</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(conditon1)}</p>
                  </td>
                </tr>
              )}
              {/* 3rd item */}
              {!!taxable3 && (
                <tr>
                  <td className="td_of_charts">Next Tk.</td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(taxable3)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">From</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(amount3)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">10%</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(conditon2)}</p>
                  </td>
                </tr>
              )}
              {/* 4th item */}
              {!!taxable4 && (
                <tr>
                  <td className="td_of_charts">Next Tk.</td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(taxable4)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">From</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(amount4)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">15%</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(conditon3)}</p>
                  </td>
                </tr>
              )}
              {/* 5th item */}
              {!!taxable5 && (
                <tr>
                  <td className="td_of_charts">Next Tk.</td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(taxable5)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">From</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(amount5)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">20%</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(conditon4)}</p>
                  </td>
                </tr>
              )}
              {/* 6th item */}

              {!!taxable6 && (
                <tr>
                  <td className="td_of_charts">Next Tk.</td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(taxable6)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">From</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(amount6)}</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">25%</p>
                  </td>
                  <td className="withoutInputFields">
                    <p className="text-center">{format.format(conditon5)}</p>
                  </td>
                </tr>
              )}

              {/* Calculating Total */}
              <tr>
                <th colSpan={5}>Total Payable</th>
                <td className="withoutInputFields">
                  <p className="text-center">{format.format(conditon6)}</p>
                </td>
              </tr>
              {/* end of all items and table */}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2
              className="accordion-header"
              onClick={() => {
                if (props.title === props.active) {
                  props.setActive("");
                } else props.setActive(props.title);
              }}
              id="headingOne"
            >
              <button
                className={
                  "accordion-button " +
                  (props.title === props.active ? "togolBtn" : "")
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Total Payable Tax{" "}
                <span>
                  {props.title !== props.active
                    ? " " + format.format(conditon6)
                    : ""}
                </span>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className={
                "accordion-collapse collapse " +
                (props.active === props.title ? "show" : "")
              }
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body"></div>
              <div className="table-responsive">
                <table className="table table-hover table-bordered border-dark ">
                  {/* All headings */}
                  <thead>
                    <tr className="total_of_IncomeDetails">
                      <th scope="col" className="text-center"></th>
                      <th scope="col" className="text-center"></th>
                      <th scope="col" className="text-center"></th>
                      <th scope="col" className="text-center"></th>
                      <th scope="col" className="text-center">
                        Rate
                      </th>
                      <th scope="col" className="text-center">
                        Tax
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* 1st item */}
                    {!!taxable1 && (
                      <tr>
                        <td className="withoutInputFields td_of_charts">
                          First Tk.
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(taxable1)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">From</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(amount1)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">0%</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">0</p>
                        </td>
                      </tr>
                    )}

                    {/* 2nd item */}
                    {!!taxable2 && (
                      <tr>
                        <td className="td_of_charts">Next Tk.</td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(taxable2)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">From</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(amount2)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">5%</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(conditon1)}
                          </p>
                        </td>
                      </tr>
                    )}

                    {/* 3rd item */}
                    {!!taxable3 && (
                      <tr>
                        <td className="td_of_charts">Next Tk.</td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(taxable3)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">From</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(amount3)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">10%</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(conditon2)}
                          </p>
                        </td>
                      </tr>
                    )}

                    {/* 4th item */}
                    {!!taxable4 && (
                      <tr>
                        <td className="td_of_charts">Next Tk.</td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(taxable4)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">From</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(amount4)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">15%</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(conditon3)}
                          </p>
                        </td>
                      </tr>
                    )}

                    {/* 5th item */}
                    {!!taxable5 && (
                      <tr>
                        <td className="td_of_charts">Next Tk.</td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(taxable5)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">From</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(amount5)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">20%</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(conditon4)}
                          </p>
                        </td>
                      </tr>
                    )}

                    {/* 6th item */}

                    {!!taxable6 && (
                      <tr>
                        <td className="td_of_charts">Next Tk.</td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(taxable6)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">From</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(amount6)}
                          </p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">25%</p>
                        </td>
                        <td className="withoutInputFields">
                          <p className="text-center">
                            {format.format(conditon5)}
                          </p>
                        </td>
                      </tr>
                    )}

                    {/* Calculating Total */}
                    <tr>
                      <th colSpan={5}>Total Payable</th>
                      <td className="withoutInputFields">
                        <p className="text-center">
                          {format.format(conditon6)}
                        </p>
                      </td>
                    </tr>
                    {/* end of all items and table */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Totaltax;
