export const taxConfig = {
  taxRules: {
    // THESE ARE THE AMOUNT FOR TAX RATE, TAX RATE IS ADJUSTED WITH RESPECT TO BELOW CONFIGS
    general: [350000, 100000, 400000, 500000, 500000, 99999999],
    disabled: [475000, 100000, 400000, 500000, 500000, 99999999],
    freedomFighters: [500000, 100000, 400000, 500000, 500000, 99999999],
    oldAge: [400000, 100000, 400000, 500000, 500000, 99999999],
  },
  lessAmount: {
    maxHousing: 300000,
    maxHousingPercentage: 50,
    maxMedical: 120000,
    maxMedicalPercentage: 10,
    maxConveyance: 30000,
  },
  zone: {
    cityCorporation: 5000,
    otherCity: 4000,
    restCountry: 3000,
  },
  config: {
    configureC44: 1000000,
    ConfigureC45: 3000000,
    ConfigureC46: 250000,
    ConfigureC49: 250000,
    ConfigureC50: 500000,
    ConfigureH44: 15,
    ConfigureH46: 15,
    ConfigureH47: 12,
    ConfigureH49: 15,
    ConfigureH50: 12,
    ConfigureH51: 10,
    // maxInvestTaxExemption: 25,
    maxInvestTaxExemption: 20,
    maxAllowedInvesment: 15000000,
    maxAmountForLesserAmount: 450000,
    numberToBeMultipliedForTaxPayableAmount: 2 / 3, // 2/3 of the total income is tax payable
    rebatePercentageOnInvestment: 3, // 3%
  },
};
